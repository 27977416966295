@mixin phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

@mixin a-button {
  a {
    color: $gold;

     &:hover {
        opacity: 0.8;
     }

     &:visited {
        opacity: 0.8;
     }
  }
}

$gold: #D1A33D;
$floralwhite: #FAF7EC;
$white: #fff;
$black: #3d3d3d;
$red: #ff0000;
$darkgrey: #3B3B3B;
$lightred: #FBE3DF;
