@font-face {
	font-family: Branch;
	src: url("../fonts/Branch.eot");
	src: url("../fonts/Branch.woff") format("woff"),
	url("../fonts/Branch.woff2") format("woff2"),
	url("../fonts/Branch.ttf") format("truetype"),
	url("../fonts/Branch.svg#filename") format("svg");
}
@font-face {
	font-family: Poppins;
	src: url("../fonts/Poppins-Regular.eot");
	src: url("../fonts/Poppins-Regular.woff") format("woff"),
	url("../fonts/Poppins-Regular.woff2") format("woff2"),
	url("../fonts/Poppins-Regular.ttf") format("truetype"),
	url("../fonts/Poppins-Regular.svg#filename") format("svg");
}
@font-face {
	font-family: PoppinsLight;
	src: url("../fonts/Poppins-Light.eot");
	src: url("../fonts/Poppins-Light.woff") format("woff"),
	url("../fonts/Poppins-Light.woff2") format("woff2"),
	url("../fonts/Poppins-Light.ttf") format("truetype"),
	url("../fonts/Poppins-Light.svg#filename") format("svg");
}
@font-face {
	font-family: PoppinsMedium;
	src: url("../fonts/Poppins-Medium.eot");
	src: url("../fonts/Poppins-Medium.woff") format("woff"),
	url("../fonts/Poppins-Medium.woff2") format("woff2"),
	url("../fonts/Poppins-Medium.ttf") format("truetype"),
	url("../fonts/Poppins-Medium.svg#filename") format("svg");
}

h1, h2 {
  font-family: var(--standard-font-family), var(--font-fallback);
}
