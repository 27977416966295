cognito-ui .login_modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  font-size: 16px;
  font-family: var(--light-font-family),var(--font-fallback);
  width: 100vw;
  form {
      overflow: visible;
      margin: 0;
      padding: 0;
  }
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    background: no-repeat center url(../../img/loading.gif);
    background-size: contain;

    &Overlay{
      background: rgba( 255, 255, 255, 0.9 );
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1001;
      top: 0;
      animation: fadeIn 0.3s;
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
      border-bottom-left-radius: 1em;

      @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }

      &ImageContainer{
        position: fixed;
        z-index: 1003;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% );
      }
    }
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90%;
    max-height: 100%;
    z-index: 103;
    transition: all 0.3s ease 0s;
    transform: translate3d(calc(-50% + 0px), calc(-50% + 0px), 0px) scale3d(1, 1, 1);
    width: 100%;
    overflow: auto;
    padding: 10px 0;
    
    @include tablet-portrait-up {
      width: 75%;
    }

    &::-webkit-scrollbar{
      display: none;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 0;
      height: 100%;
      vertical-align: middle;
    }

    .content {
      background: $white;
      border-top-right-radius: 1em;
      border-bottom-right-radius: 1em;
      border-bottom-left-radius: 1em;
      padding: 1em;
      margin-top: -0.1em;
      display: grid;
      position: relative;
      @include tablet-portrait-up {
        padding: 2em;
      }

      &.rounded_corners{
        border-top-left-radius: 1em;
      }

      .close {
        float:right;
        height: 30px;
        background-image: url(../../img/x.png);
        width: 30px;
        position: absolute;
        right: 5px;
        top: 20px;
        display: block;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 50%;
        @include tablet-portrait-up {
          right: 10px;
          top: 25px;
          margin-top: initial;
        }
      }
    }

    .paragraph {
      margin-bottom: 0.5em;

    }

    .wrapper {
      @include tablet-portrait-up {
        display: grid;
        grid-template-columns: 50% 50%;
      }

      .cola {
        a {
          color: $gold;
          font-size: 0.8em;
          text-decoration: underline;;
        }
        @include tablet-portrait-up {
          padding-right: 1em;
        }
      }

      .colb {
        background-color: $gold;
        border-radius: 1em;
        padding: 1.5em;
        color: $white;
        text-align: center;
        font-weight: 200;
        &.signup{
          padding: 0;
          background-color: transparent;
        }
        .title {
          font-family: var(--light-font-family), var(--font-fallback);
          font-size: 2.6em;
          margin: 0;
          font-weight: 400;
          color: #fff;
          @include tablet-portrait-up {
            font-size: 2.3em;
          }
        }

        .description {
          padding: 1em 0;
          display: block;
          font-family: var(--light-font-family), var(--font-fallback);
          font-size: 1.1em;
        }
      }
    }

    .heading {
      display: block;
      margin: 0 0 0.8em;
      padding: 0.5em 0 0;
      font-size: 3.5em;
      line-height: 1em;
      text-align: center;
      font-family: var(--headings-font-family), var(--font-fallback);
      color: $gold;
      @include tablet-portrait-up {
        font-size: 50px;
      }
    }

    #top a[class^="navbutton"]:hover {
      color: white;
    }

    input {
      background-color: $floralwhite;
      padding: 1.3em 1em;
      width: 100%;
      border: 0;
      margin-bottom: 1em;
      font-weight: 400;
      color: $black;
      font-family: var(--standard-font-family),var(--font-fallback);
      font-size: 14px;
      height: auto;
      outline-color: #000;
      border-radius: 2px;

      &#placeholder,
      &::placeholder,
      &::-webkit-input-placeholder {
        color: $black;
      }

      &:hover {
        opacity: 0.8;
      }

      &[type=submit] {
        background-color: $gold;
        width: 100%;
        padding: 0.8em 3em;
        border-radius: 30px;
        color: $white;
        float: right;
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        height: auto;
        @include tablet-portrait-up {
          width: initial;
          padding: 1.2em 3em;
        }
      }

      .error input {
        background-color: $lightred;
      }
    }

    .navbutton {
      background-color: transparent;
      border-radius: 28px;
      border: 1px solid $white;
      display: inline-block;
      cursor: pointer;
      color: $white;
      margin-top: 1em;
      font-family: var(--standard-font-family), var(--font-fallback);
      font-size: 15px;
      padding: 14px;
      text-decoration: none;
      text-shadow: 0px 1px 0px $gold;
      width: 100%;

      @include tablet-portrait-up {
        padding: 16px 31px;
        width: inherit;
      }

      &[type=submit] {
        background-color: $gold;
        width: 100%;
        padding: 0.8em 2em;
        border-radius: 30px;
        color: $white;
        float: right;
        cursor: pointer;
        font-size: 16px;
        @include tablet-portrait-up {
          width: initial;
          padding: 1.2em 3em;
        }
      }

      &:hover {
        opacity: 0.8;
      }

      &:active {
        position: relative;
        top: 1px;
      }
    }
  }
}

.signInFormError{
  color: $red;
}
