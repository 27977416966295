* {
	box-sizing: border-box;
}

:root {
  --font-fallback: Arial, sans-sans;
  --standard-font-family: "Poppins", sans-serif;
  --light-font-family: "PoppinsLight", sans-serif;
  --medium-font-family: "PoppinsMedium", sans-serif;
  --headings-font-family: "Branch", serif;
  --font-heading-scale: clamp(2.8rem, 4vw + 1rem, 3.2rem);
}

body {
	margin: 2em;
  font-family: var(--standard-font-family), var(--font-fallback);
  font-size: 14px;
}

.loading {
  margin: 40px auto;
}

:disabled {
  opacity: 0.75;
}
