.tabs {
  display: inline;

  .tab {
    cursor: pointer;
    transition: background ease 0.2s;
    flex-grow: 1;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-color: $floralwhite;
    color: $gold;
    font-size: 16px;
    padding: 0.7em 1.5rem;
    text-decoration: none;
    display: inline-block;
    font-weight: 500;
    font-family: var(--standard-font-family),var(--font-fallback);
    @include tablet-portrait-up {
        padding: 0.7em 2rem;
        font-size: 18px;
    }

    &.active{
      background-color: #fff;
    }
  }
}
